import React, { useState, useEffect, useRef } from 'react';
import { faCircleChevronLeft, faCircleChevronRight, faCircle, faTrash, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import moment from 'moment'
import useAuth from '../../../hooks/useAuth';
import Modal from '../../../components/Modal';
import SlideOut from '../../../components/AppointmentsSlideOut';

const MonthCalendar = () => {
  const axios = useAxiosPrivate();
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const dayAbbreviations = daysOfWeek.map(day => day.charAt(0));
  const [open, setOpen] = useState(false)
  const { setShowLoader, auth } = useAuth()
  const [showModal, setShowModal] = useState(false)

  const [appointments, setAppointments] = useState([])
  const [users, setUsers] = useState([])
  const [filteredAppointments, setFilteredAppointments] = useState([])
  const [selectedAppointment, setSelectedAppointment] = useState(null)

  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [selectedDay, setSelectedDay] = useState(moment().format('D'));

  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
  const [selectedUser, setSelectedUser] = useState("");
  const [loadedUsers, setLoadedUsers] = useState(false)

  const calendarRef = useRef(null);

  const handlePrevMonth = () => {
    setDate(moment(date).subtract(1, 'months').toDate());
  };

  const handleNextMonth = () => {
    setDate(moment(date).add(1, 'months').toDate());
  };

  const handleClickDay = () => {
    const selectedDateStr = moment(date).format('YYYY-MM-') + (selectedDay < 10 ? '0' + selectedDay : selectedDay);
    setSelectedDate(selectedDateStr);

    const filtered = appointments.filter(appointment => moment(appointment.start_date).format('YYYY-MM-DD') === selectedDateStr);
    setFilteredAppointments(filtered);
  };

  useEffect(() => {
    if(auth.user.id) {
      setSelectedUser(String(auth.user.id))
    }
  }, [auth])

  useEffect(() => {
    getUsers()
    if(selectedUser) {
      getAppointments()
    }
  }, [selectedUser])

  useEffect(() => {
    handleClickDay()
  }, [appointments, selectedDay])

  useEffect(() => {
    if(users.length > 0) setLoadedUsers(true)
  }, [users])

  const getAppointments = () => {
    setShowLoader(true)
    axios.get('/appointments/all-users/' + selectedUser).then(res => {
      if(res.data) {
        setAppointments(res.data.result)
        setShowLoader(false)
      }
    }).catch(err => {
      console.error(err)
      setShowLoader(false)
    })
  }

  const getUsers = () => {
    axios.get('/users/all').then(res => {
      if(res.data) {
        setUsers(res.data.result)
      }
    }).catch(err => {
      console.error(err)
    })
  }

  const isCurrentDay = (day) => {
    return moment(day).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');
  }

  const resetModal = () => {
    setShowModal(false)
    setSelectedAppointment(null)
  }
  
  const CancelAppointmentElement = () => {
    const cancelAvailability = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      try {
        await axios.get(`/appointments/delete/${selectedAppointment.id}`).then(res => {
          console.info(`Cancelled appointment with id: ${selectedAppointment.id}`)
          getAppointments()
          resetModal(false)
          setShowLoader(false)
        })
      } catch (error) {
        setShowLoader(false)
      }
    }

    return (
      <form onSubmit={cancelAvailability}>
        <div className="mb-3">
          <p className="text-lg font-bold my-8 text-center">
            Are you sure you want to cancel this appointment?
          </p>
          <p className="text-lg font-bold my-8 text-center">
            {moment(selectedAppointment.start_date).format('DD/MM/YYYY')} at {moment(selectedAppointment.start_date).format('HH:mm')} for {selectedAppointment?.appointmentable?.name ? selectedAppointment.appointmentable.name : selectedAppointment?.appointmentable?.task_info?.request_information?.claim_reference}
          </p>
          <div className="flex mt-2 w-full">
            <button type="button" className="btn mt-4 mr-4 w-1/2" onClick={resetModal}>Back</button>
            <button className="btn red mt-4 w-1/2">Confirm</button>
          </div>
        </div>
      </form>
    )
  }

  const renderDays = () => {
    let totalDays = moment(date).daysInMonth();
    let firstDay = moment(date).startOf('month').day();
    let days = [];
    let hasAppointments = appointments.length !== 0 ? true : false

    for (let i = 0; i < firstDay; i++) {
      days.push(<div key={`empty-${i}`} className="rounded-lg bg-gray-200 h-full"></div>);
    }
    
    for (let day = 1; day <= totalDays; day++) {
      let dayMoment = moment(moment(date).format('YYYY-MM-')  + (day < 10 ? '0' + day : day), 'YYYY-MM-DD');
      let isCurrentDate = isCurrentDay(dayMoment.format('YYYY-MM-DD'));
      let isSelectedDate = dayMoment.format('YYYY-MM-DD') === selectedDate;
      let hasAppointment = false;
      if(hasAppointments) {
        hasAppointment = appointments.some(appointment => dayMoment.isSame(appointment['start_date'], 'day'));
      }

      days.push(
        <div
          key={day}
          className={`calendar-day flex flex-col justify-center items-center px-2 py-4 text-center relative
            ${isSelectedDate ? 'bg-light-blue' : isCurrentDate ? 'bg-violet-200' : ''} 
            rounded-lg cursor-pointer hover:bg-violet-200`}
          onClick={() => {setSelectedDay(day); handleClickDay()}}
        >
          <div>{day}</div>
          {hasAppointment && <FontAwesomeIcon icon={faCircle} className="absolute bottom-0 h-2 w-2 mb-1" />}
        </div>
      );
    }

    return days;
  };

  return (
    <div className="p-4">
      <div className=" pb-4 mb-4 border-b flex items-center">
          <p className="mr-4">Showing appointments for: </p>
            {users.length > 0 && selectedUser ? (
              <select value={String(selectedUser)} onChange={(e) => setSelectedUser(String(e.target.value))} className="p-2 border rounded-md max-w-[400px]">
                {users.map((user) => (
                  <option key={user.id} value={String(user.id)}>{user.name}</option>
                ))}
              </select>
            ) : (
              <select className="p-2 border rounded-md max-w-[400px]" defaultValue={""}>
                <option disabled value="">Loading...</option>
              </select>
            )}
      </div>
      <div className='flex w-full bg-white rounded-xl space-x-4 mb-2'>
        {showModal && (
          <Modal title={'Cancel Appointment'} body={<CancelAppointmentElement />} show={resetModal} />
        )}
        <div className="border rounded-xl w-1/2" ref={calendarRef}>
          {/* Calendar Header */}
          <div className="calendar-header bg-nav rounded-t-xl text-white px-2 py-4 flex justify-between items-center">
            <button onClick={handlePrevMonth} className='hover:bg-hub-primary-hover rounded-lg py-2 px-4'><FontAwesomeIcon icon={faCircleChevronLeft} className="cursor-pointer h-5 w-5 " /></button>
            <h2 className='font-medium'>{moment(date).format('MMMM YYYY')}</h2>
            <button onClick={handleNextMonth} className='hover:bg-hub-primary-hover rounded-lg py-2 px-4'><FontAwesomeIcon icon={faCircleChevronRight} className="cursor-pointer h-5 w-5 " /></button>
          </div>
    
          {/* Calendar Days */}
          <div className="calendar-days grid grid-cols-7 gap-1 p-1 pb-2 text-sm text-dark-blue">
            {dayAbbreviations.map((dayAbbr, index) => (
              <div key={index} className={`calendar-day-header font-bold px-2 py-4 text-center ${index >= 1 ? 'hidden md:block' : ''} ${index >= 4 ? 'hidden lg:block' : ''}`}>
                {dayAbbr}
              </div>
            ))}
            {renderDays()}
          </div>
        </div>

        {/* Appointments Section */}
        <div className="w-1/2 space-y-2 text-dark-blue overflow-y-auto relative">
          <div className='flex items-start justify-between'>
            <div className='text-lg font-medium mb-3'>{isCurrentDay(selectedDate) ? "Today's" : moment(selectedDate).format('dddd, Do MMMM')} Appointments</div>
          </div>
          {filteredAppointments && filteredAppointments.length > 0 ? (
            filteredAppointments
              .sort((a, b) =>
                moment(a.start_date).unix() - moment(b.start_date).unix()
              )
              .map((appointment, index) => {
              const appointmentDate = moment(appointment.start_date);
              const dayName = `${appointmentDate.format('dddd Do MMMM')}`;

              return (
                <div key={appointment.id} className="space-y-2">
                  <div className='bg-blue-50/50 rounded-lg p-2 flex items-center justify-between text-xs space-x-2'>
                    <div className='wrap text-[16px]'>
                      <FontAwesomeIcon icon={faClock} className="mr-2 text-nav" />
                      {appointmentDate.format('h:mm A')} - <a href={appointment.appointmentLink} className="text-blue-500 underline">{appointment?.appointmentable?.name ? appointment.appointmentable.name : appointment?.appointmentable?.task_info?.request_information?.claim_reference}</a>
                    </div>
                    <div>
                      {moment(appointment.end_date).isAfter(moment()) && (
                        <button onClick={(e) => { e.preventDefault(); setSelectedAppointment(appointment); setShowModal(true); }} className="rounded-lg">
                          <FontAwesomeIcon icon={faTrash} className="cursor-pointer h-4 w-4 text-red-500 hover:text-red-400" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div>No appointments found</div>
          )}
          <button className="btn absolute bottom-0 right-0 mr-4" onClick={() => setOpen(true)}>
            View All
          </button>
        </div>
        <SlideOut open={open} setOpen={setOpen} appointments={appointments}/>
      </div>
    </div>
    
  );
};

export default MonthCalendar;

