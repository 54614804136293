import MainTitle from '../../components/MainTitle'
import { useState, useEffect } from 'react'
import Calendar from './Partials/MonthCalendar';
import Tasks from './TasksList'
import moment from 'moment'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth';
import Todo from '../../components/TodoSlideOut'

function Dashboard() {
  const { auth } = useAuth()
  const axios = useAxiosPrivate();
  const [availabilities, setAvailabilities] = useState({})
  const [open, setOpen] = useState(false)

  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return 'Good Morning'
    } else if (currentHour < 18) {
      return 'Good Afternoon'
    } else {
      return 'Good Evening'
    }
  }

  const getAvailabilities = async () => {
    try {
      const response = await axios.get('/availabilities/all')
      setAvailabilities(response.data.result)
    } catch (error) {
      console.error("Error fetching availabilities:", error)
    }
  }

  // Group availabilities by date
  const groupByDate = (availabilities) => {
    return availabilities.reduce((grouped, availability) => {
      const date = moment(availability.start_time).format('dddd Do MMM'); // Group by day (e.g., "Tues 22nd Oct")
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push(availability);
      return grouped;
    }, {});
  };

  // Sort dates and group availabilities
  const sortedAvailabilities = () => {
    const grouped = groupByDate(availabilities);
    return Object.keys(grouped)
      .sort((a, b) => moment(b, 'dddd Do MMM').unix() - moment(a, 'dddd Do MMM').unix()) // Sort dates descending
      .map(date => ({ date, availabilities: grouped[date] }));
  };

  useEffect(() => {
    getAvailabilities()
  }, []);

  return (
    <section>
      <MainTitle title={`${getGreeting()}, ${auth?.user?.name}`} status links />
      <Todo open={open} setOpen={setOpen} />
      <div className="grid grid-cols-2 gap-6 my-5 ">
        <div className='col-span-1'>
          <Tasks />
        </div>
        <div className='col-span-1 w-full'>
          <div className='rounded-xl bg-white'>
            <Calendar />
          </div>
          <section className="shadow mt-4 py-6 px-4 rounded-xl bg-white">
            <div className=''>
              <div className='space-y-4'>
                <div className='flex items-center justify-between mb-6'>
                  <h2 className="text-2xl font-medium leading-6 text-dark-blue">Availability</h2>
                  <p className="text-gray-600">{moment().format('DD/MM/YYYY')}</p>
                </div>
                <div className='h-[28rem] overflow-y-scroll'>
                  {availabilities.length > 0 ? (
                    sortedAvailabilities().map((group) => (
                      <div key={group.date}>
                        <div className='font-semibold mb-2'>{group.date}</div>
                        {group.availabilities.map((availability) => (
                          <div className='flex items-start mb-4' key={availability.id}>
                            <div className='w-14 mr-4'>
                              <img
                                src={availability.userProfile}
                                alt="User Profile"
                                className="w-12 h-12 rounded-full border border-gray-100"
                              />
                            </div>
                            <div className='w-full'>
                              <div className='font-medium'>{availability.user}</div>
                              <div className='flex items-center justify-between text-[12px]'>
                                <div>{moment(availability.start_time).format('h:mm a')} - {moment(availability.end_time).format('h:mm a')}</div>
                                <div className='flex justify-end'>
                                  <span className='text-emerald-600 text-xs text-right'>{availability.type}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))
                  ) : (
                    <div className='bg-white rounded-2xl shadow-md p-4 flex items-center space-x-4 w-full'>
                      <p className="no-records">No listed availability</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  )
}

export default Dashboard
