import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark, faPlus, faClock } from '@fortawesome/free-solid-svg-icons'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useAuth from '../hooks/useAuth'

const SlideOut = ({ open, setOpen, appointments }) => {

  const [appointmentsByDate, setAppointmentsByDate] = useState({});

  useEffect(() => {
    const groupedAppointments = {};

    appointments.forEach((appointment) => {
      const date = moment(appointment.start_date).format('YYYY-MM-DD');

      if (!groupedAppointments[date]) {
        groupedAppointments[date] = [];
      }
      groupedAppointments[date].push(appointment);
    });

    setAppointmentsByDate(groupedAppointments);
  }, [appointments]);

  useEffect(() => {
  }, [appointmentsByDate]);
  


  const AppointmentsElement = () => {
    return (
      <>
      {Object.keys(appointmentsByDate).sort((a, b) => new Date(a) - new Date(b)).map((date, dateIndex) => {
        return (
          <div key={dateIndex} className="space-y-2 pb-8">
            <div className='text-sm'>{moment(date).format('dddd Do MMMM')}</div>
            {appointmentsByDate[date].map((appointment, appointmentIndex) => {
              return (
                <div key={appointmentIndex} className='bg-blue-50/50 rounded-lg p-2 flex items-center justify-between text-xs space-x-2'>
                  <div className='wrap text-[12px]'>
                    <FontAwesomeIcon icon={faClock} className="mr-1.5 text-sm text-nav" />
                    {moment(appointment.start_date).format('h:mm A')} - <a href={appointment.appointmentLink} className="text-blue-500 underline">{appointment?.appointmentable?.name ? appointment.appointmentable.name : appointment?.appointmentable?.task_info?.request_information?.claim_reference}</a>
                  </div>
                  <div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
      </>
    )
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={setOpen}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="bg-nav px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <div>
                          <Dialog.Title className="text-lg font-semibold leading-6 text-white">
                            All Appointments Assigned To You
                          </Dialog.Title>
                        </div>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-nav text-indigo-200 hover:text-white focus:outline-none"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <FontAwesomeIcon className="text-white hover:text-white" icon={faCircleXmark} />
                          </button>
                        </div>
                      </div>
                    </div>
                    
                    <div className="space-y-2 text-dark-blue overflow-y-auto p-8">
                      {appointments.length > 0 ? (
                        <AppointmentsElement />
                      ) : (
                        <div>No appointments found</div>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default SlideOut
